<template>
  <div>
    <v-divider></v-divider>
    <v-card-text class="px-4">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          @click="toGoAdicionarTempo"
          color="secondary"
          class="mr-4 my-4 white--text"
          small
        >
          <v-icon>mdi-plus</v-icon></v-btn
        >
      </div>

      <template v-if="historicos">
        <v-card
          outlined
          v-for="(colaborador, i) in historicos.colaboradores"
          :key="i"
          :class="{ 'mt-8': i != 0 }"
        >
          <v-card-title class="px-4 py-0 text-subtitle-1">
            {{ colaborador.colaborador }}
            <v-spacer> </v-spacer>

            <v-divider vertical class="mx-4"></v-divider>
            Total: {{ colaborador.total }}
            <v-divider vertical class="mx-4"></v-divider>

            <v-btn
              icon
              :class="`session${colaborador.expand ? '--active' : ''}`"
              @click="colaborador.expand = !colaborador.expand"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider v-show="colaborador.expand"></v-divider>

          <v-slide-y-transition>
            <div class="pa-6" v-show="colaborador.expand">
              <v-row>
                <v-col
                  v-for="(tempo, j) in colaborador.historico"
                  :key="j"
                  cols="12"
                  md="4"
                >
                  <div
                    style="background-color: #f5f5f5"
                    class="d-flex px-6 py-4"
                  >
                    <div>
                      <div>
                        <span class="text-subtitle-1 font-weight-bold">
                          Inicio:
                        </span>
                        {{ tempo.inicio | dateFormat("PPPpp") }}
                      </div>

                      <div class="mt-2">
                        <span class="text-subtitle-1 font-weight-bold">
                          Fim:
                        </span>
                        {{ tempo.fim | dateFormat("PPPpp") }}
                      </div>

                      <div class="mt-2">
                        <span class="text-subtitle-1 font-weight-bold">
                          Total:
                        </span>
                        {{ tempo.total }}
                      </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="d-flex">
                      <div>
                        <div>
                          <v-btn icon small @click="toGoEditarHistorico(tempo)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                        <div class="mt-2">
                          <v-btn
                            @click="openDeleteTempo(tempo.id)"
                            icon
                            small
                            class="error--text"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-slide-y-transition>
        </v-card>
      </template>

      <div v-else class="d-flex align-center justify-center text-center mt-10">
        Nenhum registro de tempo da atividade foi adicionado ainda!
      </div>

      <EditarHistoricoTempo
        v-if="dialogEditarHistorico"
        :dialogEditarHistorico.sync="dialogEditarHistorico"
        :tempo="tempo"
        @fetch-historico="getAtividadesHistoricos"
      />
      <AdicionarHistoricoTempo
        v-if="dialogAdicionarHistorico"
        :dialogAdicionarHistorico.sync="dialogAdicionarHistorico"
        :atividade_id="atividade_id"
        @fetch-historico="getAtividadesHistoricos"
      />
      <DeleteHistoricoTempo
        v-if="dialogDeleteTempo"
        :dialogDeleteTempo.sync="dialogDeleteTempo"
        :item_id="delete_id"
        @fetch-historico="getAtividadesHistoricos"
      />
    </v-card-text>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import { fetchAtividadesClientesHistoricos } from "@/api/gestor/atividades_clientes.js";
import { mapState } from "vuex";

export default {
  name: "AtividadesHistoricosTempos",

  components: {
    EditarHistoricoTempo: () => import("./EditarHistoricoTempo.vue"),
    AdicionarHistoricoTempo: () => import("./AdicionarHistoricoTempo.vue"),
    DeleteHistoricoTempo: () => import("./DeleteHistoricoTempo.vue"),
  },

  props: {
    atividade_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      historicos: [],
      expanded: [],
      singleExpand: false,
      dialogEditarHistorico: false,
      dialogAdicionarHistorico: false,
      tempo: {},
      delete_id: null,
      dialogDeleteTempo: false,
    };
  },

  computed: {
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),

    headers() {
      return [
        { text: "", value: "data-table-expand" },
        {
          text: "colaborador",
          value: "colaborador",
        },
        {
          text: "total",
          value: "total",
        },
      ];
    },
    expandedHeaders() {
      return [
        {
          text: "Data inicio",
          value: "inicio",
        },
        {
          text: "Hora inicio",
          value: "inicio",
        },
        {
          text: "Data fim",
          value: "fim",
        },
        {
          text: " Hora fim",
          value: "fim",
        },
        {
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ];
    },
  },

  methods: {
    toGoEditarHistorico(item) {
      this.tempo.data = format(parseISO(item.inicio), "yyyy-MM-dd");
      this.tempo.hora_inicio = format(parseISO(item.inicio), "HH:mm");
      this.tempo.hora_fim = format(parseISO(item.fim), "HH:mm");
      this.tempo.id = item.id;
      this.tempo.note = item.note;
      this.tempo.jornada_trabalho = item.jornada_colaborador;
      this.dialogEditarHistorico = true;
    },
    toGoAdicionarTempo() {
      this.dialogAdicionarHistorico = true;
    },

    getAtividadesHistoricos() {
      this.loading = true;
      return fetchAtividadesClientesHistoricos(this.atividade_id)
        .then((response) => {
          this.historicos = response;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDeleteTempo(item) {
      this.delete_id = item;
      this.dialogDeleteTempo = true;
    },
  },

  async mounted() {
    await this.getAtividadesHistoricos();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .session--active .v-icon {
  transform: rotate(-180deg);
}
</style>
